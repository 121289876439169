<script>
  import { mdiSendOutline } from "@mdi/js";
  import { computed, defineComponent } from "@vue/composition-api";

  import { useEstimateItemsByConstructionRecordId } from "@/composables/useEstimateItems";
  import { useRouter } from "@/helpers";
  import { formatCurrency } from "@/helpers/filter";
  import themeConfig from "@/themeConfig";
  // SFCs
  import ContractorContactInfoSection from "@/views/apps/construction-record/construction-record-preview/ContractorContactInfoSection.vue";
  import { useConstructionRecord } from "@/views/apps/construction-record/useConstructionRecord.js";
  import { useProject } from "@/views/apps/project/useProject.js";

  export default defineComponent({
    components: {
      ContractorContactInfoSection
    },
    setup() {
      const { route } = useRouter();
      const { project, projectHasLoaded } = useProject();
      const projectId = route.value.params.project;
      const constId = route.value.params.record;
      const { estimateItems, estimateItemsHaveLoaded } =
        useEstimateItemsByConstructionRecordId();

      const selectedEstimateItems = computed(() => {
        return estimateItems.value.filter((i) => i.item_qty !== null);
      });

      const { constructionRecord } = useConstructionRecord(
        route.value.params.record
      );

      const calcSubtotal = () => {
        return selectedEstimateItems.value.reduce(
          (previous, current) =>
            previous + current.item_cost * current.item_qty,
          0
        );
      };

      const printInvoice = () => {
        window.print();
      };

      return {
        projectId,
        constId,
        project,
        projectHasLoaded,
        estimateItems,
        selectedEstimateItems,
        estimateItemsHaveLoaded,
        constructionRecord,
        formatCurrency,
        calcSubtotal,
        // themeConfig
        reportAgency: themeConfig.reporting.tsAgency,
        reportDept: themeConfig.reporting.tsDept,
        reportTitle: themeConfig.reporting.tsTitle,
        appLogo: themeConfig.app.logoDark,
        icons: {
          mdiSendOutline
        },
        printInvoice
      };
    }
  });
</script>
<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9">
      <v-card
        v-if="projectHasLoaded && constructionRecord && estimateItemsHaveLoaded"
      >
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between">
            <!-- Left Content -->
            <div class="mb-6">
              <div class="d-flex align-center mb-6">
                <v-img
                  :src="appLogo"
                  max-height="30px"
                  max-width="30px"
                  alt="logo"
                  contain
                  class="me-3"
                ></v-img>
                <span class="text--primary font-weight-semibold text-xl">
                  {{ reportAgency }}
                </span>
              </div>
              <!-- <span class="d-block font-weight-semibold text-lg">{{ reportDept }} {{ reportTitle }}</span> -->
              <span class="d-block">DelDOT Traffic Office</span>
              <span class="d-block">900 Public Safety Boulevard</span>
              <span class="d-block">Dover, DE 19901</span>
              <span class="d-block">1-800-867-5309</span>
            </div>
            <!-- Right Content -->
            <div>
              <p class="font-weight-medium text-xl text--primary mb-2">
                Project #{{ project.proceed_num }}
              </p>
              <p class="mb-2">
                <span
                  >Permit
                  <strong>#{{ constructionRecord.permit_num }}</strong></span
                >
              </p>

              <p class="mb-2">
                <span
                  >Date Generated:
                  <strong>{{
                    new Date().toLocaleString().slice(0, 9)
                  }}</strong></span
                >
              </p>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <!-- Contractor Details -->
        <v-card-text class="py-9 px-8">
          <div
            class="contractor-details d-flex justify-space-between flex-wrap flex-column flex-sm-row"
          >
            <contractor-contact-info-section
              :organization-id="project.contractor_record"
            />
          </div>
        </v-card-text>
        <v-divider></v-divider>

        <!-- Selected Estimate Items -->
        <v-simple-table class="selected-items-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ITEM</th>
                <th class="text-left">DESCRIPTION</th>
                <th class="text-right">COST</th>
                <th class="text-center">UOM</th>
                <th class="text-right">QTY</th>
                <th class="text-right">TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in selectedEstimateItems" :key="item.item_num">
                <td class="text-no-wrap">
                  {{ item.item_num }}
                </td>
                <td class="text-left">
                  {{ item.item_desc }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(item.item_cost) }}
                </td>
                <td class="text-center">
                  {{ item.item_unit }}
                </td>
                <td class="text-right">
                  {{ item.item_qty }}
                </td>
                <td class="text-right">
                  {{ formatCurrency(item.item_cost * item.item_qty) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Traffic Statement Total -->
        <v-card-text class="py-9 px-8">
          <div
            class="invoice-total d-flex justify-space-between flex-column flex-sm-row"
          >
            <div class="mb-2 mb-sm-0">
              <p class="mb-1">
                <span class="font-weight-semibold">Prepared by:</span>
                <span>{{ project.designer_nm }}</span>
              </p>
              <p>{{ project.design_firm_nm }}</p>
            </div>
            <div>
              <table class="w-full">
                <tr>
                  <td class="pe-16">Subtotal:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ formatCurrency(calcSubtotal()) }}
                  </th>
                </tr>
                <tr>
                  <td class="pe-16">Contingency:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{ project.contingency }}%
                  </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">Total:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                    {{
                      formatCurrency(
                        calcSubtotal() * (project.contingency / 100) +
                          calcSubtotal()
                      )
                    }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <v-btn class="mb-3" color="primary" block @click="printInvoice">
            Print / Save as PDF
          </v-btn>
          <v-btn
            class="mb-3"
            color="primary"
            block
            outlined
            :to="{
              name: 'apps-construction-record-edit',
              params: {
                project: projectId,
                record: constId
              }
            }"
          >
            Edit
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<style lang="scss">
  @import "~@/preset/preset/apps/invoice.scss";

  @media print {
    .v-application {
      background: none !important;
    }

    .app-navigation-menu,
    .v-app-bar,
    .v-footer,
    .product-buy-now,
    .app-invoice-preview.row .col-12:last-of-type {
      display: none !important;
    }

    .app-invoice-preview.row .col-12:first-child {
      max-width: 100% !important;
      flex: 1 !important;
    }

    .v-main,
    .app-content-container {
      padding: 0px !important;
    }

    .v-card {
      box-shadow: none !important;
    }

    .app-invoice-preview {
      .invoice-header,
      .contractor-details,
      .invoice-total {
        &.d-flex {
          flex-direction: row !important;
        }
      }
    }
  }
</style>
