<script>
  import { defineComponent, ref } from "@vue/composition-api";

  import { useContactByOrganizationId } from "@/composables/useContacts";
  import { useGetOrganizationById } from "@/composables/useOrganizations";

  export default defineComponent({
    props: {
      organizationId: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const { contacts, contactsArePending, contactsHaveLoaded } =
        useContactByOrganizationId(props.organizationId);

      const { organizationRecord } = useGetOrganizationById(
        props.organizationId
      );
      let mergedContact = ref({});

      if (contacts && organizationRecord) {
        console.log(contacts.value);
        const ownerContact = contacts.value.filter(
          (contact) => contact.contact_type == "Owner"
        )[0];
        console.log(ownerContact);

        const pocContact = contacts.value.filter(
          (contact) => contact.tpm_receive_ntp == "Contractor POC"
        )[0];
        console.log(pocContact);

        mergedContact = Object.assign(pocContact, {
          address: ownerContact.address,
          city: ownerContact.city,
          state: ownerContact.state,
          zip_code: ownerContact.zip_code
        });
        console.log(mergedContact);

        console.log(organizationRecord);
      }

      return {
        contacts,
        contactsArePending,
        contactsHaveLoaded,
        organizationRecord,
        mergedContact
      };
    }
  });
</script>

<template>
  <div
    v-if="contacts && contactsHaveLoaded && organizationRecord"
    class="mb-8 mb-sm-0"
  >
    <p class="font-weight-semibold contractor-details-header">Contractor:</p>
    <p class="mb-1">
      {{ organizationRecord.full_name }}
    </p>
    <p class="mb-1">
      {{ mergedContact.address }}
    </p>
    <p class="mb-1">
      {{ mergedContact.city }}, {{ mergedContact.state }}
      {{ mergedContact.zip_code }}
    </p>
    <p v-if="mergedContact.phone_office" class="mb-1">
      {{ mergedContact.phone_office }}
    </p>
    <p v-if="mergedContact.full_name" class="mb-1">
      {{ mergedContact.full_name }}
    </p>
    <p v-if="mergedContact.email" class="mb-0">
      {{ mergedContact.email }}
    </p>
  </div>
</template>
